<template>
  <div>
    <el-card class="mb20" shadow="hover">
      <el-row type="flex" :gutter="20" align="middle">
          <el-input
            :placeholder="$t('search')"
            clearable
            v-model="filter.search"
            @keyup.enter.native="get_list"
          ></el-input>
      </el-row>
       <el-row class="mt20" type="flex" :gutter="20" align="middle">
          <span class="text">{{$t('QA.status')}}:</span>
          <el-radio-group size="medium" v-model="filter.status">
               <el-radio label>{{$t('all')}}</el-radio>
              <el-radio :label="0">{{status(0)}}</el-radio>
              <el-radio :label="1">{{status(1)}}</el-radio>
          </el-radio-group>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
          <el-button @click="get_list" type="primary">{{$t('search_zh')}}</el-button>
          <el-button @click="reset" type="info">{{$t('btnTip.reset')}}</el-button>
        </el-row>
    </el-card>
    <el-card>
      <div class="flexBC mb20">
        <div></div>
        <el-button
          type="primary"
          @click="outExe"
          >{{$t('outExe')}}</el-button>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{padding: '6px 0'}"
        :headerCellStyle="{background:'rgba(51, 55, 68, 1)',color:'#fff'}"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
      <template v-slot:user__name="slotProps">
          <div>
            {{slotProps.callback.row.user.username}}
          </div>
        </template>
         <template v-slot:real__name="slotProps">
          <div>
            {{slotProps.callback.row.user.real_name}}
          </div>
        </template>
        <template v-slot:question="slotProps">
          <div class="mutiple" v-html="slotProps.callback.row.question">
          </div>
        </template>
        <template v-slot:status="slotProps">
          <el-tag :type="statusTag(slotProps.callback.row.status)">{{status(slotProps.callback.row.status)}}</el-tag>
        </template>
        <template v-slot:operation="slotProps">
          <el-tooltip class="item" effect="dark" :content="$t('btnTip').check" placement="top">
            <el-button
              @click="handleClick('check',slotProps.callback.row)"
              icon="el-icon-view"
              type="success"
              size="mini"
            ></el-button>
          </el-tooltip>
          <el-button
              @click="handleClick('top',slotProps.callback.row)"
              :type="slotProps.callback.row.is_top ? 'info' : 'success'"
              size="mini"
            >{{slotProps.callback.row.is_top ? $t('QA.is_top0') : $t('QA.is_top1')}}</el-button>
          <el-tooltip class="item" effect="dark" :content="$t('btnTip').edit" placement="top">
            <el-button
              @click="handleClick('edit',slotProps.callback.row)"
              type="warning"
              size="mini"
            >{{$t('QA.answer')}}</el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" :content="$t('btnTip').delete" placement="top">
            <el-button
              @click="remove(slotProps.callback.row.id)"
              icon="el-icon-delete"
              type="danger"
              size="mini"
            ></el-button>
          </el-tooltip>
        </template>
      </my-table>
      <page :total="list_data.total" :page_size.sync="pageSize" :page.sync="page" />
    </el-card>
  </div>
</template>

<script>
import {  mixin_list } from "@/mixins";
import myTable from '@/components/table'
import page from "@/components/page";
import {  getQA, deleteQA, topQA } from '@/api/index';
export default {
  mixins: [ mixin_list(getQA)],
  components: {
    page,
    myTable
  },
  data () {
    return {
      filter: {
        search: '',
        status:'',
        category:''
      },
      columns: [
        {
          label: 'ID',
          prop: 'id',
          sortable:true
        }, 
        {
          label: this.$t('QA.create_time'),
          prop: 'create_time',
          sortable:true
        },
        {
          label: this.$t('QA.update_time'),
          prop: 'update_time',
          sortable:true
        },
        {
          label: this.$t('QA.username'),
          prop: 'user__name',
          slot:'user__name',
          sortable:true
        },
        {
          label: this.$t('QA.real_name'),
          prop: 'real__name',
          slot: 'real__name',
          sortable:true
        },
        {
          label: this.$t('QA.title'),
          prop: 'title',
          sortable:true
        },
        {
          label: this.$t('QA.question'),
          prop: 'question',
          sortable:true,
          slot:'question'
        },
        {
          label: this.$t('QA.status'),
          prop: 'status',
          slot:'status',
          sortable:true
        },
        {
          label: this.$t('operation'),
          prop: '',
          width: 300,
          slot: 'operation'
        }]
    }
  },
  methods: {
      status(status){
          switch(status){
              case 0:
                  return this.$t('QA.status0')
                case 1:
                  return this.$t('QA.status1')
          }
      },
      statusTag(statusTag){
        switch(statusTag){
          case 0:
            return 'info'
          case 1:
            return 'success'
        }
      },
    async handleClick (value, item) {
      switch (value) {
        case 'edit':
            this.$router.push(`/customerCenter/qa/edit?id=${item.id}`)
          break;
        case 'check':
            this.$router.push(`/customerCenter/qa/detail?id=${item.id}`)
          break;
        case 'top':
          await topQA({id: item.id,is_top:!item.is_top})
          this.get_list()
      }
    },
    remove (id) {
      this.$confirm(this.$t('QA.sureDeleteQA'), this.$t('tips'), {
        confirmButtonText: this.$t('btnTip.submit'),
        cancelButtonText: this.$t('btnTip.cancel'),
        type: 'warning'
      }).then(async () => {
        await deleteQA(id)
        this.get_list()
      }).catch(() => {
        
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.text{
  width:100px;
  font-size: 16px;
}
/deep/ label{
  margin-bottom:0!important
}
</style>